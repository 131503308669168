import React, { Component } from "react"
import { navigate } from "gatsby"
import auth from "../../utils/auth"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { connect } from "react-redux"
import { storeCurrentRequestId, storeCountry } from "../../redux/actions"
import axios from "axios"
import WorkTypeChoices from "./WorkTypeChoices"
import { Link } from "gatsby"
import Layout from "../layout"
import Helmet from "react-helmet"
const API = process.env.API
export class StartRequestForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      email: "",
      country: "",
      user: {},
      required_by: new Date(),
      workTypeSelected: "",
      error: "",
      warning: "",
      formClassName: "start-form-wrapper",
      screenHeight: null,
      loading: false,
      late_request: false,
      late_reason: "",
    }
  }
  addMonths(date, months) {
    date.setMonth(date.getMonth() + months)
    return date
  }
  checkScreenHeight() {
    this.setState({ screenHeight: window.innerHeight - 75 })
  }
  componentDidMount() {
    this.checkScreenHeight()
    window.addEventListener("resize", this.checkScreenHeight.bind(this))
    const userInfo = auth.getUserInfo()
    const { department } = this.props.ownProps
    if (userInfo) {
      this.setState({
        user: userInfo,
        email: userInfo.email,
        name: userInfo.username,
      })
    }
    switch (department) {
      case "video":
        this.setState({ workTypeSelected: "videos" })
        break
      case "social-media":
        this.setState({ workTypeSelected: "new-social-media-content" })
        break
      case "product-promotion":
        this.setState({ workTypeSelected: "new-promotion" })
        break
      case "documents":
        this.setState({ workTypeSelected: "new-document" })
        break
      default:
        this.setState({ workTypeSelected: "" })
    }
  }
  submitRequestForm(e) {
    e.preventDefault()
    this.setState({ error: "", loading: true })
    const requestTimeLimit = this.props.requiredTime || 5
    const { required_by } = this.state
    const now = new Date()
    const minDate = now.setDate(now.getDate() + requestTimeLimit)
    const valid = new Date(required_by) > minDate
    setTimeout(() => {
      if (valid || this.state.late_reason) {
        this.storeUsersRequest()
      } else {
        this.setState({
          loading: false,
          error:
            "The required by date is not a realistic timeframe for the work required",
          late_request: true,
        })
      }
    }, 10)
  }
  storeUsersRequest() {
    const token = auth.getToken()
    const userInfo = auth.getUserInfo()
    axios({
      method: "get",
      url: `${API}/users/${userInfo.id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => {
        this.addRequestToUserHistory(res)
      })
      .catch(error => {
        console.log(error)
      })
  }
  changeCountry(country) {
    this.setState({ country })
    const countryCode = this.getCountryCode(country.toLowerCase())
    this.props.storeCountry(countryCode)
  }
  getCountryCode(country) {
    switch (country) {
      case "new zealand":
        return "nz"
      case "australia":
        return "au"
      case "botswana":
        return "africa"
      case "ghana":
        return "gh"
      case "hong kong":
        return "hk"
      case "malaysia":
        return "my"
      case "indonesia":
        return "in"
      case "namibia":
        return "africa"
      case "nigeria":
        return "ng"
      case "philippines":
        return "ph"
      case "singapore":
        return "sg"
      case "uganda":
        return "ug"
      case "thailand":
        return "th"
      case "south africa":
        return "za"
      case "zambia":
        return "zm"
      default:
        return ""
    }
  }
  addRequestToUserHistory(res) {
    const token = auth.getToken()
    const userInfo = auth.getUserInfo()
    const request_history = res.data.request_history || []
    // setup 'request' data
    const created = new Date()
    const {
      name,
      email,
      workTypeSelected,
      country,
      required_by,
      late_reason,
      late_request,
    } = this.state
    const { department } = this.props.ownProps
    const status = "In Progress"
    const data = {
      name,
      email,
      department,
      workTypeSelected,
      country,
      id: request_history.length,
      created: +created,
      updated: +created,
      required_by,
      status,
      late_request,
      late_reason,
    }

    request_history.push(data)
    const updateData = {
      request_history,
    }
    // save to user id
    axios({
      method: "put",
      url: `${API}/users/${userInfo.id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: updateData,
    }).then(() => {
      // save to redux and reroute to form
      this.props.storeCurrentRequestId(data)
      navigate(`/${department}/${workTypeSelected}`)
    })
  }
  changeWorkType({ target }) {
    this.setState({ workTypeSelected: target.value })
  }
  changeRequiredByDate(e) {
    this.setState({ required_by: e })
    const now = new Date()
    const requestTimeLimit = this.props.requiredTime || 5
    const minDate = now.setDate(now.getDate() + requestTimeLimit)
    const valid = new Date(e) >= minDate
    if (valid || this.state.late_reason) {
      this.setState({ error: "", late_request: false })
    } else {
      this.setState({
        late_request: true,
        error:
          "The required by date is not a realistic timeframe for the work required",
      })
    }
  }

  render() {
    const textClass =
      "text-sm p-3 border rounded-lg w-full border-gray-100 bg-white focus:border-2 focus:border-blue-300 focus:shadow-sm bg-white focus:outline-shadow transition-all duration-300"
    const {
      name,
      email,
      required_by,
      workTypeSelected,
      country,
      error,
      formClassName,
      screenHeight,
      loading,
      late_reason,
      late_request,
    } = this.state
    const {
      departmentSection,
      webSection,
      types,
      department,
      introRequestContainer,
      introRequestContainerStepTwo,
      introDatePicker,
      introDatePickerNote,
    } = this.props.ownProps

    return (
      <Layout>
        <Helmet title="Create A Request" htmlAttributes={{ lang: "en" }} />
        <div
          style={{ minHeight: screenHeight ? `${screenHeight}px` : null }}
          className={`min-h-screen flex items-center start-form-wrapper-container bg-gradient`}
        >
          <div
            className={`bg-white rounded-lg shadow-xl mx-auto container text-black p-10 border ${formClassName} ${introRequestContainer} ${introRequestContainerStepTwo}`}
          >
            <h1 className="text-brand-blue text-2xl font-medium mb-4">
              Create a new request | {department}
            </h1>
            <p className="text-1xl mb-10">
              Please nominate 1 point of contact from your team. Any changes
              must come through that 1 person and is best if possible for the
              changes to be discussed and agreed upon with your team before
              being submitted to the Creative Dept/Monique.
            </p>
            <form onSubmit={this.submitRequestForm.bind(this)}>
              <div className="flex flex-wrap lg:-mx-5">
                <div className="w-full lg:px-5 lg:w-6/12 mt-2 mb-6">
                  <label
                    className="block text-sm font-medium text-brand-blue mb-2"
                    htmlFor="name"
                  >
                    Name
                  </label>
                  <input
                    required
                    name="name"
                    value={name}
                    className={textClass}
                    onChange={e => {
                      this.setState({ name: e.target.value })
                    }}
                  />
                </div>
                <div className="w-full lg:px-5 lg:w-6/12 mt-2 mb-6">
                  <label
                    className="block text-sm font-medium text-brand-blue mb-2"
                    htmlFor="email"
                  >
                    Email
                  </label>
                  <input
                    required
                    type="email"
                    name="email"
                    value={email}
                    className={textClass}
                    onChange={e => {
                      this.setState({ email: e.target.value })
                    }}
                  />
                </div>
              </div>
              <div className="lg:flex lg:-mx-5">
                <div className="w-full lg:px-5 lg:w-6/12 mt-2 mb-6">
                  <label
                    className="block text-sm font-medium text-brand-blue mb-2"
                    htmlFor="country"
                  >
                    Country Office
                  </label>
                  <select
                    required
                    type="text"
                    id="country"
                    name="country"
                    value={country}
                    className={textClass}
                    onChange={e => {
                      this.changeCountry(e.target.value)
                    }}
                    onBlur={e => {
                      this.changeCountry(e.target.value)
                    }}
                  >
                    <option> </option>
                    <option>Australia</option>
                    <option>Botswana</option>
                    <option>Ghana</option>
                    <option>Indonesia</option>
                    <option>Hong Kong</option>
                    <option>Malaysia</option>
                    <option>Nigeria</option>
                    <option>Namibia</option>
                    <option>New Zealand</option>
                    <option>Philippines</option>
                    <option>Taiwan</option>
                    <option>Thailand</option>
                    <option>Rwanda</option>
                    <option>South Africa</option>
                    <option>Zambia</option>
                    <option>Vietnam</option>
                  </select>
                </div>
                <div
                  className={`${"mt-2 mb-4 w-full lg:px-5 lg:w-6/12"} ${introDatePicker} ${introDatePickerNote}`}
                >
                  <label
                    className="block text-sm font-medium text-brand-blue mb-2"
                    htmlFor="required_by"
                  >
                    What date is this work required for?
                  </label>
                  <DatePicker
                    departmentSection={departmentSection}
                    id="required_by"
                    name="required_by"
                    selected={required_by}
                    onChange={this.changeRequiredByDate.bind(this)}
                    dateFormat="yyyy/MM/dd"
                    className={textClass}
                    minDate={new Date()}
                  />
                </div>
              </div>
              {late_request && (
                <div className="w-full w-full mt-2 mb-6">
                  <label
                    className="block text-sm font-medium text-brand-blue mb-2"
                    htmlFor="email"
                  >
                    Please explain why it wasn't possible to submit this request
                    in a timely manner ({this.props.requiredTime || 5} days)
                  </label>
                  <input
                    required
                    type="text"
                    name="late_request"
                    value={late_reason}
                    className={textClass}
                    onChange={e => {
                      this.setState({ late_reason: e.target.value })
                    }}
                  />
                </div>
              )}
              <WorkTypeChoices
                types={types}
                webSection={webSection}
                departmentSection={departmentSection}
                department={department}
                workTypeSelected={workTypeSelected}
                changeWorkType={this.changeWorkType.bind(this)}
              />

              {loading && (
                <div className="h-full fixed w-full left-0 top-0 bg-white z-50">
                  <div className="min-h-screen flex w-full items-center">
                    <div className="text-brand-blue w-full max-w-sm mx-auto block">
                      <h4 className="text-lg font-bold text-center">
                        Creating A New Request...
                      </h4>
                      <h4 className="text-lg text-center blink-1">
                        Please Wait
                      </h4>
                      <div className="my-5">
                        <EllipsesLoader />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {loading ? (
                <span className="inline-block bg-gray-700 text-white rounded py-2 px-4 mr-3 my-3">
                  Starting a Request
                </span>
              ) : (
                <button
                  type="submit"
                  id="submit"
                  className="bg-green-700 hover:bg-green-600 transition duration-300 text-white rounded py-2 px-4 mr-3 my-3"
                >
                  Start New Request
                </button>
              )}

              <Link
                to="/"
                className="bg-gray-300 hover:bg-gray-200 duration-300 transition text-black rounded px-10 py-2 inline-block"
              >
                Go Back
              </Link>
              <div
                className={
                  error
                    ? "transition-all duration-100 mt-2 opacity-1 animation-wobble"
                    : "transition-all duration-100 mt-2 opacity-0 overflow-hidden"
                }
              >
                <p
                  className="bg-yellow-100 text-yellow-700 rounded p-2 px-4 inline-block border border-yellow-200"
                  style={{
                    minWidth: "200px",
                  }}
                >
                  <span className="flaticon-round-error-symbol opacity-75"></span>
                  &ensp;{error}
                </p>
              </div>
            </form>
          </div>
        </div>
      </Layout>
    )
  }
}

export const EllipsesLoader = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{ margin: "auto", background: "transparent", display: "block" }}
      width="50px"
      height="50px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle
        cx={50}
        cy={50}
        fill="none"
        stroke="#72bb20"
        strokeWidth={10}
        r={35}
        strokeDasharray="164.93361431346415 56.97787143782138"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1s"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
        />
      </circle>
    </svg>
  )
}

const mapStateToProps = (state, props) => ({
  current_request_id: state.current_request_id,
  ownProps: props,
  requiredTime: props.requiredTime,
})

function mapDispatchToProps(dispatch) {
  return {
    storeCurrentRequestId: id => dispatch(storeCurrentRequestId(id)),
    storeCountry: country => dispatch(storeCountry(country)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(StartRequestForm)
