import React, { Component } from "react"

export default class WorkTypeChoices extends Component {
  constructor(props) {
    super(props)
    this.state = {
      types: {
        designTypes: [
          {
            label: "Product promotions flyer",
            value: "product-specials-flyers",
          },
          {
            label: "Event Promotion Flyer",
            value: "event-promotion-flyers",
          },
          {
            label: "Royalty flyer",
            value: "royalty-flyers",
          },
          {
            label: "Virtual Event Flyers",
            value: "virtual-event-promotion-flyers",
          },
          {
            label: "General Design",
            value: "general-design",
          },
        ],
        webTypes: [
          {
            label: "Web Design Required",
            value: "web-designs",
          },
          {
            label: "Web Issues & Errors",
            value: "issues",
          },
        ],
        videoTypes: [],
      },
    }
  }

  render() {
    const { department, departmentSection, webSection } = this.props
    const designTypes = this.state.types.designTypes.map((item, key) => (
      <div className="flex items-center w-full" key={key}>
        <div
          className={`${"toggle-switch w-16 mr-4"} ${departmentSection}`}
          data-checked={this.props.workTypeSelected === item.value}
        >
          <input
            type="radio"
            value={item.value}
            name="work-type"
            required
            className="mx-2"
            onChange={this.props.changeWorkType}
          />
          <label
            htmlFor="work-type"
            className={
              this.props.workTypeSelected === item.value
                ? "flaticon-check-symbol"
                : "flaticon-check-symbol opacity-0"
            }
          >
            <span className="hidden">{item.label}</span>
          </label>
        </div>
        <label
          htmlFor="work-type"
          className="text-black inline-block ml-4 text-sm"
        >
          {item.label}
        </label>
      </div>
    ))
    const webTypes = this.state.types.webTypes.map((item, key) => (
      <div className="flex items-center w-full" key={key}>
        <div
          className={`${"toggle-switch w-16 mr-4"} ${webSection}`}
          data-checked={this.props.workTypeSelected === item.value}
        >
          <input
            type="radio"
            value={item.value}
            name="work-type"
            required
            className="mx-2"
            onChange={this.props.changeWorkType}
          />
          <label
            htmlFor="work-type"
            className={
              this.props.workTypeSelected === item.value
                ? "flaticon-check-symbol"
                : "flaticon-check-symbol opacity-0"
            }
          >
            <span className="hidden">{item.label}</span>
          </label>
        </div>
        <label
          htmlFor="work-type"
          className="text-black inline-block ml-4 text-sm"
        >
          {item.label}
        </label>
      </div>
    ))
    const videoTypes = this.state.types.videoTypes.map((item, key) => (
      <div className="flex items-center w-full" key={key}>
        <div
          className="toggle-switch w-16 mr-4"
          data-checked={this.props.workTypeSelected === item.value}
        >
          <input
            type="radio"
            value={item.value}
            name="work-type"
            required
            className="mx-2"
            onChange={this.props.changeWorkType}
          />
          <label
            htmlFor="work-type"
            className={
              this.props.workTypeSelected === item.value
                ? "flaticon-check-symbol"
                : "flaticon-check-symbol opacity-0"
            }
          >
            <span className="hidden">{item.label}</span>
          </label>
        </div>
        <label
          htmlFor="work-type"
          className="text-black inline-block ml-4 text-sm"
        >
          {item.label}
        </label>
      </div>
    ))
    return (
      <div>
        {this.state.types.videoTypes.length > 0 && (
          <p className="text-sm mb-2">
            Select the type of work you wish to request:{" "}
          </p>
        )}
        {department === "design" && this.state.types.designTypes.length > 0 && (
          <div
            className="toggle-switch-wrapper block"
            style={{
              display: "block",
            }}
          >
            {designTypes}
          </div>
        )}
        {department === "web" && this.state.types.webTypes.length > 0 && (
          <div
            className="toggle-switch-wrapper block"
            style={{
              display: "block",
            }}
          >
            {webTypes}
          </div>
        )}
        {department === "video" && this.state.types.videoTypes.length > 0 && (
          <div
            className="toggle-switch-wrapper block"
            style={{
              display: "block",
            }}
          >
            {videoTypes}
          </div>
        )}
      </div>
    )
  }
}
